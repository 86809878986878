























import { Component, Vue } from "vue-property-decorator";

@Component
export default class ButtonsListExample extends Vue {
  public btns = [
    [
      { class: "btn-black" },
      { class: "btn-black-light" },
      { class: "btn-black-outline" },
      { class: "btn-black-link" },
    ],
    [
      { class: "btn-blue" },
      { class: "btn-blue-light" },
      { class: "btn-blue-outline" },
      { class: "btn-blue-link" },
    ],
    [
      { class: "btn-green" },
      { class: "btn-green-light" },
      { class: "btn-green-outline" },
      { class: "btn-green-link" },
    ],
    [
      { class: "btn-red" },
      { class: "btn-red-light" },
      { class: "btn-red-outline" },
      { class: "btn-red-link" },
    ],
    [
      { class: "btn-gray" },
      { class: "btn-gray-light" },
      { class: "btn-gray-outline" },
      { class: "btn-gray-link" },
    ],
  ];
}
