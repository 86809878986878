



































import { Component, Vue } from "vue-property-decorator";
import AnswerStatusBar from "@/components/UI/AnswerStatusBar.vue";

@Component({
  components: { AnswerStatusBar },
})
export default class AnswerStatusBarExample extends Vue {}
