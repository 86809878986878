












import { Component, Vue } from "vue-property-decorator";
import Pagination from "@/components/UI/Pagination.vue";

@Component({
  components: { Pagination },
})
export default class PaginationExample extends Vue {
  public page = 2;
  public perPage = 25;
  public total = 532;
}
