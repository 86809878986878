










import { Component, Vue } from "vue-property-decorator";
import Timer from "@/components/UI/Timer.vue";

@Component({
  components: { Timer },
})
export default class TimerExample extends Vue {
  public deadline = 2169642; // 36min 10c
}
