















































import { Component, Vue } from "vue-property-decorator";
import SwitchContent from "@/components/UI/SwitchContent.vue";

@Component({
  components: { SwitchContent },
})
export default class SwitchContentExample extends Vue {}
