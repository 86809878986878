













import { v4 } from "uuid";
import { Component, Vue } from "vue-property-decorator";
import TaskEditor from "@/components/editor/TaskEditor.vue";
import TaskPreview from "@/components/editor/TaskPreview.vue";
import { TaskType } from "@/types/API/QuestionKitsType";

@Component({
  components: {
    TaskEditor,
    TaskPreview,
  },
})
export default class TaskEditorExample extends Vue {
  public taskData: TaskType = {
    id: v4(),
    title:
      "Чи відноситься до пов’язаної особи службова (посадова) особа учасника процедури закупівлі, уповноважена здійснювати від імені учасника процедури закупівлі юридичні дії, спрямовані на встановлення, зміну або припинення цивільно-правових відносин, а також члени сім’ї такої службової (посадової) особи",
    answers: [
      {
        id: v4(),
        title:
          "критерії із заданими наперед параметрами, використання яких дає можливість автоматично здійснювати вибір процедур закупівель, що містять ознаки порушень законодавства у сфері публічних закупівель",
        is_right: false,
      },
      {
        id: v4(),
        title:
          "критерії сукупності вартості предмета закупівлі або його частини (лота) та інших витрат, які нестиме безпосередньо замовник під час використання, обслуговування та припинення використання предмета закупівлі;",
        is_right: true,
      },
      {
        id: v4(),
        title: "свідоцтво про здобуття базової середньої освіти;",
        is_right: false,
      },
      {
        id: v4(),
        title:
          "шляхом введення до штатного розкладу виключно за погодженням з Кабінетом Міністрів України",
        is_right: false,
      },
    ],
  };
}
