












import { Vue, Component } from "vue-property-decorator";

@Component
export default class TooltipExample extends Vue {
  public text =
    "Неможливо редагувати активні та завершені тестування. Створіть копію, щоб вносити зміни та додавати нові питання";
}
