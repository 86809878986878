


























import { Emit, Component, Vue, Prop } from "vue-property-decorator";
import { InputValueType } from "@/types/form/InputValueType";
import { SelectOptionType } from "@/types/form/SelectOptionType";

@Component
export default class RadioGroupField extends Vue {
  @Prop({ type: Boolean, default: false }) isSwitcher!: boolean;
  @Prop({ type: Array, required: true }) readonly options!: SelectOptionType[];
  @Prop({ type: [String, Boolean, Number], default: "" })
  value!: InputValueType;

  @Emit()
  public input(event: InputValueType): InputValueType {
    return event;
  }
}
