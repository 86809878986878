



















































import { Component, Vue } from "vue-property-decorator";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";

@Component({
  components: { MaterialIcon },
})
export default class IconsListExample extends Vue {
  public goggleIcons = [
    {
      outline: true,
      name: "school",
    },
    {
      outline: true,
      name: "info",
    },
    {
      outline: true,
      name: "sync",
    },
    {
      outline: true,
      name: "notifications_off",
    },
    {
      outline: true,
      name: "check_circle",
    },
    {
      outline: true,
      name: "highlight_off",
    },
    {
      outline: true,
      name: "engineering",
    },
    {
      outline: true,
      name: "format_list_bulleted",
    },
    {
      outline: true,
      name: "chevron_left",
    },
    {
      outline: true,
      name: "chevron_right",
    },
    {
      outline: true,
      name: "keyboard_arrow_down",
    },
    {
      outline: true,
      name: "keyboard_arrow_up",
    },
    {
      outline: true,
      name: "schedule",
    },
    {
      outline: true,
      name: "close",
    },
    {
      outline: true,
      name: "done",
    },
    {
      outline: true,
      name: "mail",
    },
    {
      outline: true,
      name: "more_horiz",
    },
    {
      outline: true,
      name: "add",
    },
    {
      outline: true,
      name: "arrow_back",
    },
    {
      outline: true,
      name: "edit",
    },
    {
      outline: false,
      name: "file_download",
    },
    {
      outline: false,
      name: "verified",
    },
    {
      outline: false,
      name: "search",
    },
    {
      outline: false,
      name: "delete_outline",
    },
  ];
}
