
































































import { Component, Vue } from "vue-property-decorator";
import Modal from "@/components/UI/Modal.vue";

@Component({
  components: { Modal },
})
export default class ModalWindowsExample extends Vue {
  public showModal = false;

  public onToggle(): void {
    this.showModal = !this.showModal;
  }
}
