































































































































import { v4 } from "uuid";
import { Component, Vue } from "vue-property-decorator";
import InputField from "@/components/form/InputField.vue";
import CheckboxField from "@/components/form/CheckboxField.vue";
import RadioGroupField from "@/components/form/RadioGroupField.vue";
import TextareaField from "@/components/form/TextareaField.vue";
import { SelectOptionType } from "@/types/form/SelectOptionType";

@Component({
  components: {
    InputField,
    CheckboxField,
    RadioGroupField,
    TextareaField,
  },
})
export default class FormElementsExample extends Vue {
  public checkbox = true;
  public radio = "no";
  public textField = "Some text";
  public radioGroupConfig: SelectOptionType[] = [
    {
      id: v4(),
      value: "yes",
      text: 'сукупність вартості предмета закупівлі вартість життєвого циклу"або його частини (лота) та інших витрат, які нестиме безпосередньо замовник під час використання, обслуговування та припинення використання предмета закупівлі;',
    },
    {
      id: v4(),
      value: "maybe",
      text: "ціна, зазначена учасником у тендерній пропозиції/пропозиції та перерахована за математичною формулою з урахуванням показників інших критеріїв оцінки, визначених замовником у тендерній документації/оголошенні про проведення спрощеної закупівлі;",
    },
    {
      id: v4(),
      value: "no",
      text: "ціна, яка є меншою на 40 або більше відсотків від середньоарифметичного значення ціни/приведеної ціни тендерних пропозицій інших учасників на початковому етапі аукціону, та/або є меншою на 30 або більше відсотків від наступної ціни/приведеної ціни тендерної пропозиції за результатами проведеного електронного аукціон;",
    },
    {
      id: v4(),
      value: "disable",
      disabled: true,
      text: "пропозиція щодо предмета закупівлі або його частини (лота), яку учасник подає замовнику відповідно до оголошення про проведення спрощеної закупівлі та вимог до предмета закупівлі;",
    },
  ];
  public switchRadioGroupConfig: SelectOptionType[] = [
    {
      id: v4(),
      value: "yes",
      text: "Yes",
    },
    {
      id: v4(),
      value: "maybe",
      text: "Maybe",
    },
    {
      id: v4(),
      value: "no",
      text: "No",
    },
    {
      id: v4(),
      value: "disable",
      disabled: true,
      text: "Disabled button",
    },
  ];
}
