















import { Component, Vue, Watch } from "vue-property-decorator";
import ButtonsListExample from "@/components/library/ButtonsListExample.vue";
import IconsListExample from "@/components/library/IconsListExample.vue";
import FormElementsExample from "@/components/library/FormElementsExample.vue";
import ModalWindowsExample from "@/components/library/ModalWindowsExample.vue";
import NotificationsExample from "@/components/library/NotificationsExample.vue";
import ExampleTable from "@/components/library/ExampleTable.vue";
import SwitchContentExample from "@/components/library/SwitchContentExample.vue";
import PaginationExample from "@/components/library/PaginationExample.vue";
import TypographyExample from "@/components/library/TypographyExample.vue";
import TooltipExample from "@/components/library/TooltipExample.vue";
import AnswerStatusBarExample from "@/components/library/AnswerStatusBarExample.vue";
import TaskEditorExample from "@/components/library/TaskEditorExample.vue";
import TimerExample from "@/components/library/TimerExample.vue";
import { Route } from "vue-router";
import { libraryExamplesConfig } from "@/config/libraryExamplesConfig";

@Component({
  components: {
    ButtonsListExample,
    IconsListExample,
    FormElementsExample,
    ModalWindowsExample,
    NotificationsExample,
    ExampleTable,
    SwitchContentExample,
    PaginationExample,
    TypographyExample,
    TooltipExample,
    AnswerStatusBarExample,
    TaskEditorExample,
    TimerExample,
  },
})
export default class Library extends Vue {
  public components = libraryExamplesConfig;

  @Watch("$route")
  onRouteUpdate({ params: { id } }: Route): void {
    this.scrollToComponent(id);
  }

  mounted(): void {
    const { id } = this.$route.params;
    this.scrollToComponent(id);
  }

  private scrollToComponent(id: string): void {
    const el = this.$el.querySelector(`#${id}`);

    if (el) {
      el.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }
}
