










import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import TimeView from "@/components/UI/TimeView.vue";
import { TIME_EXIT, ONE_SECOND } from "@/constants/timeConstants";

@Component({
  components: { TimeView },
})
export default class Timer extends Vue {
  @Prop({ type: Boolean, default: false }) isHighlight!: boolean;
  @Prop({ type: String, required: true }) deadline!: number;

  public currentTime = 0;

  get timeout(): string {
    return new Date(new Date().getTime() + this.deadline).toString();
  }

  get isTimeExit(): boolean {
    return this.currentTime > TIME_EXIT;
  }

  @Watch("deadline")
  private countdown(): void {
    this.currentTime =
      Date.parse(this.timeout) - Date.parse(new Date().toString());

    this.timeLeft();

    if (this.isTimeExit) {
      setTimeout(this.countdown, ONE_SECOND);
    }
  }

  mounted(): void {
    this.countdown();
  }

  @Emit()
  private timeLeft(): number {
    return this.currentTime;
  }
}
