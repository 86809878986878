














import { Component, Vue, Prop } from "vue-property-decorator";

const TIME_UNIT = 60;
const ONE_SECOND = 1000;
const ONE_MINUTE = ONE_SECOND * TIME_UNIT;
const ONE_HOUR = ONE_SECOND * TIME_UNIT * TIME_UNIT;

@Component({
  filters: {
    formatTime(value: number): string {
      const TIME_FIXED_LENGTH = 2;
      return `${value}`.padStart(TIME_FIXED_LENGTH, "0");
    },
  },
})
export default class TimeView extends Vue {
  @Prop({ type: Boolean, default: false }) isHighlight!: boolean;
  @Prop({ type: String, required: true }) time!: number;

  get seconds(): number {
    return Math.floor((this.time / ONE_SECOND) % TIME_UNIT);
  }
  get minutes(): number {
    return Math.floor((this.time / ONE_MINUTE) % TIME_UNIT);
  }
  get hours(): number {
    return Math.floor(this.time / ONE_HOUR);
  }
}
