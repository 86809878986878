
















import { v4 } from "uuid";
import { Component, Vue } from "vue-property-decorator";
import Table from "@/components/table/Table.vue";
import { TableColumnConfigType } from "@/types/table/TableColumnConfigType";

@Component({
  components: {
    "base-table": Table,
  },
})
export default class ExampleTable extends Vue {
  public tableFieldsConfig: TableColumnConfigType[] = [
    {
      id: v4(),
      name: "Версія",
      key: "version",
    },
    {
      id: v4(),
      name: "Дата створення",
      key: "created_at",
    },
    {
      id: v4(),
      name: "Статус",
      key: "status",
    },
    {
      id: v4(),
      name: "Дата зміни статусу",
      key: "updated_at",
    },
    {
      id: v4(),
      name: "",
      key: "btn",
    },
  ];
  public tableData = [
    {
      version: "v 1.02",
      created_at: "01 січ. 2021 14:04",
      status: "active",
      updated_at: "01 січ. 2021 14:04",
    },
    {
      version: "v 1.01",
      created_at: "01 січ. 2021 14:04",
      status: "archive",
      updated_at: "01 січ. 2021 14:04",
    },
    {
      version: "v 1.00",
      created_at: "01 січ. 2021 14:04",
      status: "draft",
      updated_at: "01 січ. 2021 14:04",
    },
  ];
}
